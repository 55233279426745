import { RouteLocationNormalizedLoaded } from 'vue-router'
type CosmosContext = {
  userGuid?: string
  newUser: boolean
  newClient: boolean
  newSubscriptions: {
    subscriptionId: number | undefined
    createTime: string | undefined
    paperId: number | undefined
  }[]
}

export default function () {
  const nuxtApp = useNuxtApp()

  return {
    signupCompletion: async ({
      userId,
      clientId,
      route,
      sessionId,
      ctx,
    }: {
      userId: string
      clientId: string
      route: RouteLocationNormalizedLoaded
      sessionId: string
      ctx: CosmosContext
    }) => {
      await nuxtApp.$api.cosmos
        .trialSignup({
          userId,
          clientId,
          route: {
            name: route.name,
            path: route.path,
            hash: route.hash,
            query: route.query,
            params: route.params,
            fullPath: route.fullPath,
          },
          context: {
            sessionId: sessionId,
            ...ctx,
          },
          schemaVersion: 1,
        })
        .catch((e) => console.error(e))
    },
  }
}
