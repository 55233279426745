<template>
  <div
    class="fixed xl:hidden h-17 border-t bg-white bottom-0 left-0 w-full z-50"
  >
    <div class="container">
      <ul v-if="currentPaper" class="items-center flex primary-menu pt-4">
        <template v-for="item in menuItems" :key="item.label">
          <li class="mr-5" :class="item.isHiddenOnMobile && 'hidden md:flex'">
            <NuxtLink
              :to="`${getMenuItemUrl(item)}`"
              class="font-sans text-lg hover:text-blue"
              active-class="text-blue"
            >
              {{ $t(item.label) }}
            </NuxtLink>
          </li>
        </template>
        <li class="ml-auto">
          <NuxtLink :to="{ name: 'search' }">
            <SearchIcon class="w-6 h-6" />
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchIcon from '@/assets/icons/search.svg?component'
const indexStore = useIndexStore()
const config = useRuntimeConfig()
const route = useRoute()
const nuxtApp = useNuxtApp()

const primaryMenuItems = config.public.site?.menu?.primaryMenuItems
const paperMenuItems = config.public.site?.menu?.paperMenuItems

// Computed to update automatically on route change
const menuItems = computed(() => {
  return route.params.paper ? paperMenuItems : primaryMenuItems
})

const routePrefix = computed(() => {
  return route.params.paper ? `/${route.params.paper}/` : '/'
})

const getMenuItemUrl = (item: { url: string; noPrefix?: boolean }) => {
  return `${item.noPrefix ? '/' : routePrefix.value}${
    nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url
  }`
}

const currentPaper = computed(() => indexStore.currentPaper)
</script>
