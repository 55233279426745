<template>
  <CommonLoading
    v-if="
      submitPending ||
      userTitleListStatus === 'pending' ||
      userBrancheListStatus === 'pending' ||
      userPapersStatus === 'pending' ||
      clientStatus === 'pending'
    "
  />
  <form
    v-else-if="showConfirmation === false"
    class="ml-auto mr-auto grid gap-4"
    ref="completeUserForm"
    :class="{
      'w-full md:w-[500px]': false,
      'w-64': true,
    }"
    id="form_trial_signup_complete_user"
  >
    <i18n-t
      tag="h2"
      class="font-sans font-medium text-center text-sm"
      keypath="trialSignup.stepXOfY"
      scope="global"
    >
      <template #x> 2 </template>
      <template #y> 2 </template>
    </i18n-t>
    <FormAlert :type="alertType" :message="alertMessage">
      <template v-if="client && client.jobPlace">
        <i18n-t
          v-if="existingPaperAccesses && existingPaperAccesses.length > 0"
          keypath="trialSignup.clientAndAccessFound"
          scope="global"
        >
          <template #company>
            <span>{{ client.jobPlace }}</span>
          </template>
        </i18n-t>
        <i18n-t v-else keypath="trialSignup.clientFound" scope="global">
          <template #company>
            <span>{{ client.jobPlace }}</span>
          </template>
          <template #paper>
            <span>{{
              false
                ? config.public.site.name
                : $formatList(
                    papers.map(
                      (paper) => `${config.public.site.name} ${paper.Name}`
                    )
                  )
            }}</span>
          </template>
        </i18n-t>
      </template>
    </FormAlert>
    <FormInput
      ref="nameinput"
      v-model="name"
      required
      placeholder="NameHelpText"
      label="Name"
    />
    <FormInput
      ref="phoneinput"
      v-model="phone"
      type="tel"
      required
      placeholder="PhoneShort"
      :pattern="config.public.site.formValidation?.phonePattern"
      label="Phone"
    />
    <FormSelect
      v-model="userTitleId"
      :items="userTitleList ?? []"
      placeholder="JobTitle"
      required
      label="JobTitle"
    />
    <FormInput
      ref="clientnameinput"
      v-model="clientName"
      required
      placeholder="Company"
      label="Company"
      :disabled="client && client.jobPlace ? true : undefined"
    />
    <FormSelect
      v-model="userBranchId"
      :items="userBrancheList ?? []"
      placeholder="Branch"
      required
      label="Branch"
    />
    <FormInput
      ref="zipcodeinput"
      v-model="zipCode"
      required
      placeholder="Zipcode"
      :pattern="config.public.site.formValidation?.zipCodePattern"
      label="Zipcode"
      :disabled="client && client.zipcode ? true : undefined"
    />
    <CommonPermissionBox
      v-if="!true"
      smallText
      :permissionGiven="marketingPermissionGiven"
      @update:permission="updateMarketingPermission"
    />
    <CommonButton
      :bg="false ? 'rounded-md bg-blue' : 'rounded-full bg-blue'"
      bgHover="hover:bg-denimblue"
      text="text-white"
      textHover="text-white"
      border="border-none"
      size="xlarge"
      :disabled="!completeUserForm?.checkValidity()"
      @click="submit()"
    >
      {{
        (user && user.name) || existingPaperAccesses
          ? $t('completeUser')
          : $t('CreateUser')
      }}
    </CommonButton>
  </form>
  <CommonConfirmation v-else @modal-close="emit('modal-close')">
    <h3 class="font-sans text-2xl md:text-3xl font-normal">
      {{ $t('trialSignup.confirmEmailToGainAccessTitle') }}
    </h3>
    <p class="pt-3 mx-auto text-base leading-6 text-black">
      {{ $t('trialSignup.confirmEmailToGainAccessText') }}
    </p>
  </CommonConfirmation>
</template>

<script setup lang="ts">
import type { ContentPaper } from '~/typesManual/content_api/paper'
import type { Paper, Subscription } from '~/typesAuto/apicore/v1'
import { FetchError } from 'ofetch'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const route = useRoute()
const {
  sendConfirmationEmail,
  sendConfirmationEmailJwt,
  sendAccessGrantedReceiptEmail,
} = useEmailFlow()
// Error handling
const { setAlert, clearAlert, alertType, alertMessage } = useAlert()
const dataLayer = useDataLayer()
const cosmos = useCosmos()

const indexStore = useIndexStore()
const userStore = useUserStore()

const props = defineProps<{
  email: string
  password: string
  user: TempUser
  papers: ContentPaper[]
  returnUrl?: string
}>()

const emit = defineEmits(['modal-close'])

const showConfirmation = ref(false)
const submitPending = ref(false)
const newSubscriptions = ref<Subscription[]>([])

const name = ref('')
const zipCode = ref(props.user.zipcode ?? '')
const clientName = ref('')
const userTitleId = ref(props.user.userTitleId ?? 0)
const userBranchId = ref(props.user.userBranchId ?? 0)
const phone = ref(props.user.contactPhone ?? '')
const marketingPermissionGiven = ref(false)
const completeUserForm = ref<HTMLFormElement>()

// Data Fetching
const { data: userTitleList, status: userTitleListStatus } = useAsyncData(
  async () => {
    const result = await nuxtApp.$api.userTitles.list()
    return result.map((item) => ({
      name: item.name ?? '',
      id: item.id ?? 0,
    }))
  }
)

const { data: userBrancheList, status: userBrancheListStatus } = useAsyncData(
  async () => {
    const result = await nuxtApp.$api.userBranches.list()
    return result.map((item) => ({
      name: item.name ?? '',
      id: item.id ?? 0,
    }))
  }
)

const { data: userPapers, status: userPapersStatus } = useAsyncData<Paper[]>(
  async () => {
    return nuxtApp.$api.user.papers(props.user.userId)
  }
)

const { data: client, status: clientStatus } = useAsyncData(async () => {
  const userData = await nuxtApp.$api.account.getUser(props.user.userId)
  return userData.client
})

// Computeds
const existingPaperAccesses = computed(() =>
  userPapers.value
    ?.filter(
      (paper) =>
        props.papers.some((p) => p.RecordId === paper.id) && paper.subscribed
    )
    .map((paper) => paper?.id)
)

const papersToCreateTrialsFor = computed(() =>
  props.papers.filter(
    (paper) => !existingPaperAccesses.value?.includes(paper.RecordId)
  )
)

// Watchers
watch(client, async (newClient) => {
  if (newClient) {
    alertType.value = 'info'
    // Not an error
    clearAlert()
    clientName.value = newClient.jobPlace ?? ''
    zipCode.value = newClient.zipcode ?? ''
  }
})

if (props.user?.name) {
  setAlert('trialSignup.completeUserToGainAccess', 'info')
}

const updateMarketingPermission = (newValue: boolean) => {
  marketingPermissionGiven.value = newValue
}

// Methods
async function submit() {
  submitPending.value = true
  const user = props.user
  const userID = props.user.userId
  const isNewUser = !props.user?.name // is it a new user?
  const isNewClient = !props.user.clientId

  if (!userID) {
    return
  }

  if (marketingPermissionGiven.value) {
    if (!true) {
      const { firstName, lastName } = splitUserName(name.value || '')
      const { err } = await nuxtApp.$api.user.addMarketingPermission({
        firstName: firstName,
        lastName: lastName ? lastName : null,
        email: props.email,
        phone: phone.value,
        company: clientName.value,
        jobTitle: userTitleList.value?.find(
          (item) => item.id === userTitleId.value
        )?.name,
        permissionSource: 'ATS',
        permissionSourceUrl: window.location.href,
      })

      if (err) {
        console.error(err.message, ': ', err.cause)
      }
    }
  }

  try {
    await nuxtApp.$api.user.updateUser(userID, {
      name: name.value,
      zipcode: zipCode.value,
      userTitleId: userTitleId.value,
      userBranchId: userBranchId.value,
      contactPhone: phone.value,
    })
    await userStore.refreshUser()
  } catch (error) {
    console.error(error)
    setAlert(nuxtApp.$t('login.couldNotUpdateUser'))
    submitPending.value = false
    return
  }

  // Create a client
  try {
    if (!client.value?.jobPlace) {
      const clientResponse = await nuxtApp.$api.client.createClient({
        name: name.value,
        jobPlace: clientName.value,
        telephone: phone.value,
        zipcode: zipCode.value,
        email: props.email,
      })

      if (
        clientResponse.id &&
        clientResponse.jobPlace &&
        clientResponse.zipcode
      ) {
        client.value = {
          id: clientResponse.id,
          jobPlace: clientResponse.jobPlace,
          zipcode: clientResponse.zipcode,
        }
      }
    }
  } catch {
    setAlert(nuxtApp.$t('login.couldNotCreateClient'))
    submitPending.value = false
    return
  }

  // Check if user already has access
  if (existingPaperAccesses.value) {
    console.info(
      'User already has access to these paper(s):',
      existingPaperAccesses.value
    )
  }
  if (props.papers.length > 0 && papersToCreateTrialsFor.value.length === 0) {
    console.info('User already has access to all requested papers')
    setAlert(nuxtApp.$t('login.youAlreadyHaveAccess'))
    submitPending.value = false
    return
  }

  if (client.value?.id) {
    const clientID = client.value.id
    try {
      newSubscriptions.value = await Promise.all(
        papersToCreateTrialsFor.value.map(
          async (paper) =>
            await nuxtApp.$api.subscription.createSubscription(clientID, {
              subscriptionParameters: {
                paper: paper?.RecordId,
                isTrial: 1,
                maxAllowedClientEmails: 1,
                subscriptionLength: 1,
                aOpen: 1,
                signupSourceId: 2,
              },
              subscriptionEmailsdto: [
                {
                  email: props.email,
                  userGuid: user.userGuid,
                  name: name.value,
                  recieveMail: 1,
                  textMail: 1,
                },
              ],
            })
        )
      )
    } catch {
      setAlert(nuxtApp.$t('login.couldNotCheckExistingAccessOrCreateTrials'))
      submitPending.value = false
      return
    }
  }

  if (newSubscriptions.value.length > 0) {
    const mappedSubscriptions = newSubscriptions.value.map((subscription) => ({
      subscriptionId: subscription.id,
      createTime: subscription.createTime,
      paperId: subscription.product?.paperId ?? undefined,
    }))

    await cosmos.signupCompletion({
      userId: userID.toString(),
      clientId: client.value?.id?.toString() ?? '',
      route,
      sessionId: indexStore.sessionId,
      ctx: {
        userGuid: userStore.user?.userGuid,
        newUser: isNewUser,
        newClient: isNewClient,
        newSubscriptions: mappedSubscriptions,
      },
    })
  }
  if (!user.workEmailConfirmed) {
    const returnUrl = props.returnUrl
      ? new URL(props.returnUrl)
      : new URL(useRequestURL())

    let paperIds = ''
    if (papersToCreateTrialsFor.value.length) {
      paperIds = papersToCreateTrialsFor.value
        .map((paper) => paper.RecordId)
        .join(',')

      returnUrl.searchParams.append('receiptPapers', paperIds)
    }

    let err: Error | undefined
    if (user.userGuid) {
      const response = await sendConfirmationEmailJwt(
        returnUrl.href,
        user.email,
        paperIds
      )
      err = response.err
    } else {
      const response = await sendConfirmationEmail(
        returnUrl.href,
        userID,
        'ats',
        paperIds
      )
      err = response.err
    }

    if (err) {
      setAlert(err.message)
    } else {
      showConfirmation.value = true
    }

    submitPending.value = false
  } else {
    // Send a receipt mail to all papers user've gained access to.
    const paperIds = props.papers.map((paper) => paper.RecordId).join(',')
    const { err } = await sendAccessGrantedReceiptEmail(userID, paperIds)
    if (err) {
      const error = err.cause as FetchError
      setAlert(error?.response?._data)
    } else {
      await userStore.refreshUser()
    }

    submitPending.value = false
  }
  dataLayer.trial.completeClient()
  dataLayer.ecommerce.purchase(
    papersToCreateTrialsFor.value.map((paper) => ({
      item_id: paper.Product?.[0]?.RecordId,
      item_name: paper.Product?.[0]?.Name,
      price: 0,
      quantity: 1,
    }))
  )
}
</script>
